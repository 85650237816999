<template>
  <div>
    <md-card class="md-card-global-sales">
      <md-card-header class="md-card-header-icon md-card-header-blue">
        <div class="card-icon">
          <md-icon>assignment</md-icon>
        </div>
      </md-card-header>

      <md-card-content>
        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <md-button
              class="md-just-icon md-round md-primary pull-right"
              @click="newClassSchedule"
            >
              <md-icon>add</md-icon>
              <md-tooltip>New Class Schedule</md-tooltip>
            </md-button>

            <vue-table
              v-if="vTable.headers"
              ref="vtable"
              :values="vTable.values"
              :headers="vTable.headers"
              :actions="vTable.actions"
              :options="{
                tableClass:'table'
              }"
              :search="false"
              @changed="getClassSchedules"
              @onEdit="onEdit"
              @onDelete="onDelete"
            />
          </div>
        </div>
      </md-card-content>
    </md-card>

    <ClassSchedulesForm
      v-if="showModal"
      :item="selectedClassSchedule"
      @close="closeModal"
      @onSaved="$refs.vtable.init()"
    />
  </div>
</template>

<script>
import VueTable from '@/components/Tables/VueTable.vue';
import ClassSchedulesForm from './ClassSchedulesForm.vue';

export default {
  components: {
    VueTable,
    ClassSchedulesForm,
  },
  props: [],
  data() {
    return {
      filters: {
        paginate: true,
      },
      vTable: {
        headers: [
          {
            title: 'period',
            mask: 'Period',
            sortable: true,
          },
          {
            title: 'start_time_delay_start',
            mask: 'Start Delay Starts',
            sortable: true,
          },
          {
            title: 'start_time',
            mask: 'Start Time',
            sortable: true,
          },
          {
            title: 'start_time_delay_end',
            mask: 'Start Delay Ends',
            sortable: true,
          },
          {
            title: 'end_time_delay_start',
            mask: 'End Delay Starts',
            sortable: true,
          },
          {
            title: 'end_time',
            mask: 'End Time',
            sortable: true,
          },
          {
            title: 'end_time_delay_end',
            mask: 'End Delay Ends',
            sortable: true,
          },
        ],
        actions: [
          {
            buttonClass: 'md-warning',
            tooltip: 'Edit',
            callback: 'onEdit',
            icon: 'edit',
          },
          {
            buttonClass: 'md-danger',
            tooltip: 'Delete Course',
            callback: 'onDelete',
            icon: 'close',
          },
        ],
        values: {},
      },
      showModal: false,
      selectedClassSchedule: null,
    };
  },
  mounted() {
    this.$refs.vtable.init();
  },
  methods: {
    getClassSchedules(params) {
      this.request(this.$API.CLASS_SCHEDULES, 'get', { ...params, ...this.filters }, ({ data }) => {
        this.vTable.values = data;
      });
    },
    newClassSchedule() {
      this.showModal = true;
      this.selectedClassSchedule = null;
    },
    onEdit(item) {
      this.showModal = true;
      this.selectedClassSchedule = item;
    },
    closeModal() {
      this.showModal = false;
      this.selectedClassSchedule = null;
    },
    onDelete(item) {
      this.fireConfirm(
        'Deleting Class Schedule',
        `Are you sure you want to deactivate this Class Schedule (${item.period})?`,
      )
        .then(() => {
          this.request(`${this.$API.CLASS_SCHEDULES}${item.class_schedule_id}`, 'delete', {}, () => {
            this.fireSuccess('Class Schedule deleted successfully.');
            // Reset table after response
            this.$refs.vtable.init();
          });
        });
    },
  },
};
</script>

<style scoped>
</style>
